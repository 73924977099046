<template>
  <div class="prodetail">
    <!-- <div class="debg" :style="trendspageImg"></div> -->
    <div class="title">
      <router-link :to="Homeurl">{{ Hometitle }}</router-link>
      <p><img src="../assets/img/arrow-right (1).png"></p>
      <router-link :to="Applicationsurl">{{ Applicationstitle }}</router-link>
    </div>
    <div class="dedetail">
      <div class="newname">{{ list.title }}</div>
      <iframe style="position：absolute" id="particular" frameborder="0" width="100%" height="100%" scrolling="no">
      </iframe>
      <div style="width:100%;height:80px;color: #000;align:center" class="pagenum">
        <!-- <a href="/listPage/3"  style="align:center;color: #208bcf;">	&lt;back</a> -->
        <span class="pagenum-item" v-if="prev" @click="getNewDate(prev)">Previous</span>
        <span class="pagenum-item" v-if="next" @click="getNewDate(next)">Next</span>
      </div>
    </div>
  </div>
</template>
<script>
// var oldh;
// oldh = 0;
export default {
  name: "ProDetail",
  data() {
    return {
      list: [],
      coverImg: "",
      trendspageImg: {
        backgroundImage: "",
      },
      aa: "",
      Homeurl: '',
      Hometitle: '',
      Applicationsurl: '',
      Applicationstitle: '',
      prev: null,
      next: null,
      pagenum: null,
      oldh: 0,
    };
  },
  created() {
    this.getNewDate(); //生命周期创建时调用axios函数
    this.listUrl();
  },
  computed: {
    //分页
    indexs: function () {
      var left = 1;
      var right = this.all;
      var ar = [];
      left = ((this.cur - 1) / 5) * 5 + 1;
      right = left + 4;
      if (right > this.all) right = this.all;
      while (left <= right) {
        ar.push(left);
        left++;
      }
      return ar;
    },
  },
  methods: {
    listUrl() {
      this.axios
        .get("/api/nav/menu/listAll")
        .then((res) => {
          console.log(res);
          let listurl = res.data.list;
          for (let i = 0; i < listurl.length; i++) {
            if (listurl[i].name == "Home") {
              this.Homeurl = listurl[i].url;
              this.Hometitle = listurl[i].name;
            } else if (listurl[i].name == "Applications") {
              this.Applicationsurl = listurl[i].url + '/' + listurl[i].id;
              this.Applicationstitle = listurl[i].name;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getNewDate(val) {
      var id = this.$route.params.id;
      var infoId = val ? val : this.$route.params.infoId;
      const ImageInfo = await this.axios.get("/api/nav/menu/info/" + id);
      this.coverImg = ImageInfo.data.info.coverImg;
      // this.trendspageImg.backgroundImage =
      //   "url(" + this.axios.defaults.baseURL + this.coverImg + ")";
      const res = await this.axios.get("/api/nav/menu/info/info/" + infoId);
      this.list = res.data.info;
      this.pagenum = res.data.info.id
      this.next = res.data.next
      this.prev = res.data.prev
      this.aa = this.list.particulars;

      clearInterval(this.timer);
      var iframe = document.getElementById("particular");
      iframe.contentDocument.body.innerHTML = '';
      this.oldh=0;
      iframe.height=100;
      // var a=this.aa.replace('"/upload','"http://www.skysspa.com/upload')
      // a=a.replace('"/upload','"http://www.skysspa.com/upload')
      iframe.contentWindow.document.write(this.aa);
      this.timer = setInterval(() => {
        this.getHeigh();
      }, 300);
    },
    getHeigh() {
      this.$nextTick(function () {
        //容器自适应高度
        var iframe = document.getElementById("particular");
        iframe.height = iframe.contentWindow.document.documentElement.scrollHeight; //+200;
        if (this.oldh > 0 && this.oldh == iframe.height) {
          clearInterval(this.timer); //高度不再增长后退出
        }
        console.log("iframe.height:" + iframe.height);
        // iframe.height = height;
        this.oldh = iframe.height;
      });
    },
  },
};
</script>
<style>
.prodetail {
  width: 100%;
  margin-bottom: 50px;
}

.debg {
  position: absolute;
  top: 150px;
  width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  /* background-size: 100% 100%; */
  z-index: 100;
  height: 700px;
  /* background-image: url("../../src/assets/img/new1.png"); */
  background-repeat: no-repeat;
  /* text-align: left; */
  overflow: hidden;
}

.title {
  width: 100%;
  height: 40px;
  margin: auto;
  margin-top: 60px;
  /* margin-top: 760px; */
  width: 1286px;
  color: #208bcf;
  display: flex;
  flex-flow: nowrap;
}

.title a {
  color: #208bcf;
}

.title p {
  color: #7c7c7c;
  margin: 0 5px;
}

.title p img {
  width: 13px;
  height: 13px;
  padding-top: 1px;
}

.dedetail {
  margin: 0 auto;
  min-height: 747px;
  margin-bottom: 0px;
  width: 1286px;
}

.dedetail .newname {
  padding-top: 20px;
  font-size: 50px;
  text-align: center;
  margin-bottom: 30px;
  color: black;
}

.pagenum {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.pagenum-item {
  display: inline-block;
  width: 100px;
  background-color: #62a0dd;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
}
</style>